<template>
  <div>
    <v-card width="500" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>البيانات التنبؤية</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex>
                <v-select
                  v-model="predictive.year"
                  :items="years"
                  @change="getPredictedPlan"
                  label="السنة"
                  persistent-hint
                  required
                ></v-select>
              </v-flex>

              <v-flex xs12 sm12>
                <v-text-field
                  label="المبيع الفعلي المتوقع"
                  placeholder="المبيع الفعلي المتوقع"
                  v-model="predictive.actualSold"
                  :rules="[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ]"
                  required
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الكمية الكلية المتوقع عرضها"
                  placeholder="الكمية الكلية المتوقع عرضها"
                  v-model="predictive.offeredSold"
                  :rules="[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ]"
                  required
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs12 sm12>
                <v-text-field
                  label="كمية الانتاج المتوقع"
                  placeholder="كمية الانتاج المتوقع"
                  v-model="predictive.productionOfYear"
                  :rules="[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ]"
                  required
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الكمية المتبقية المتوقعة (العرض ناقص المبيع)"
                  placeholder="الكمية المتبقية المتوقعة (العرض ناقص المبيع)"
                  :rules="[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ]"
                  v-model="predictive.totalOffered"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="save()"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background: gray" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "predictive",

  data() {
    return {
      year: "",
      valid: true,
      id: "",
      years: [],
      predictive: {
        year: "",
        actualSold: 0,
        offeredSold: 0,
        productionOfYear: 0,
        totalOffered: 0,
      },
      created() {
        this.user = JSON.parse(window.localStorage.getItem("authUser"));
        this.role = window.localStorage.getItem("roles");
        var d = new Date();
        //var date = d.getDate();
        this.month = d.getMonth() + 1;
        this.year = d.getFullYear();
        this.getPredictedPlan();
      },
    };
  },

  created() {
    var d = new Date();
    this.year = d.getFullYear();
    this.getYears();
  },

  methods: {
    getYears() {
      let y = 0;

      while (y < 10) {
        this.years.push(this.year + y);
        y++;
      }
    },

    async getPredictedPlan() {
      let url =
        "/PredictedPlan/GetPredictedPlanByYear?year=" + this.predictive.year;
      await this.ApiService.get(url)
        .then((res) => {
          this.allTargets = [];
          this.predictive = res.data.responseData;

          this.id = res.data.responseData.idpredictedData;
        })
        .catch(() => {});
    },
    save() {
      if (this.$refs.form.validate()) {
        let data = {
          ActualSold: this.predictive.actualSold,
          TotalOffered: this.predictive.totalOffered,
          ProductionOfYear: this.predictive.productionOfYear,
          OfferedSold: this.predictive.offeredSold,
          Year: this.predictive.year,
        };
        if (this.id) {
          this.ApiService.put(
            "/PredictedPlan/updatePredictedPlan?id=" + this.id,
            data
          )
            .then(() => {
              this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            })
            .catch(() => {
              this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
            });
        } else {
          this.ApiService.post("PredictedPlan/AddPredictedPlan", data)
            .then(() => {
        
              this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            })
            .catch(() => {
              this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
label .v-label .theme--light {
  left: auto;
  right: 0px;
  position: absolute;
}
</style>
