var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"500"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_c('b',[_vm._v("البيانات التنبؤية")])])]),_c('v-divider'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',[_c('v-select',{attrs:{"items":_vm.years,"label":"السنة","persistent-hint":"","required":""},on:{"change":_vm.getPredictedPlan},model:{value:(_vm.predictive.year),callback:function ($$v) {_vm.$set(_vm.predictive, "year", $$v)},expression:"predictive.year"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-text-field',{attrs:{"label":"المبيع الفعلي المتوقع","placeholder":"المبيع الفعلي المتوقع","rules":[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ],"required":""},model:{value:(_vm.predictive.actualSold),callback:function ($$v) {_vm.$set(_vm.predictive, "actualSold", $$v)},expression:"predictive.actualSold"}})],1),_c('v-divider'),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-text-field',{attrs:{"label":"الكمية الكلية المتوقع عرضها","placeholder":"الكمية الكلية المتوقع عرضها","rules":[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ],"required":""},model:{value:(_vm.predictive.offeredSold),callback:function ($$v) {_vm.$set(_vm.predictive, "offeredSold", $$v)},expression:"predictive.offeredSold"}})],1),_c('v-divider'),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-text-field',{attrs:{"label":"كمية الانتاج المتوقع","placeholder":"كمية الانتاج المتوقع","rules":[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ],"required":""},model:{value:(_vm.predictive.productionOfYear),callback:function ($$v) {_vm.$set(_vm.predictive, "productionOfYear", $$v)},expression:"predictive.productionOfYear"}})],1),_c('v-divider'),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-text-field',{attrs:{"label":"الكمية المتبقية المتوقعة (العرض ناقص المبيع)","placeholder":"الكمية المتبقية المتوقعة (العرض ناقص المبيع)","rules":[
                    (v) => !!v || 'الكمية مطلوب',
                    (v) => (v && /^[0-9]*$/.test(v)) || 'لا يحوي محارف',
                  ],"required":""},model:{value:(_vm.predictive.totalOffered),callback:function ($$v) {_vm.$set(_vm.predictive, "totalOffered", $$v)},expression:"predictive.totalOffered"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"background":"#5867dd","color":"white","margin-left":"5px"},attrs:{"dark":""},on:{"click":function($event){return _vm.save()}}},[_c('b',[_vm._v("حفظ")])]),_c('v-btn',{staticStyle:{"background":"gray"},attrs:{"dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b',[_vm._v("رجوع")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }